
import * as imgproxyRuntime$e4eyuTL4Om from '/vercel/path0/imgproxy.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "imgproxy",
  "domains": [
    "192.168.8.207"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "avif",
    "webp",
    "webp"
  ]
}

imageOptions.providers = {
  ['imgproxy']: { provider: imgproxyRuntime$e4eyuTL4Om, defaults: {"baseURL":"https://s3.ap-southeast-2.wasabisys.com/daim.dev","cdnURL":"https://imgproxy2.daim.dev","key":"974ab42ac7b41eb08fed3af9d9dd954f93a4d5a973546f4e172f513b1dc0c448d7bf1410c74de2a4e039788d01f113dffecfaa44a1b1469c87e41f247d49ccaa","salt":"0883f3c3a80feaff2c28fd2e267f4c848fbda94b69809f865da51699c6b8d15b3f73d07dda55d65fee955572a0f714bec4334bf21fe52a2e5e1ffeca4c5d3b3f"} }
}
        